import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import './DirectionBlock.scss';
import DefaultImage from '../../../assets/images/history.png';

let classNames = require('classnames');

const DirectionBlock = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  name,
  post,
  dob,
  textDetails,
  mobile
}) => {
  return (
    <div className={classNames('direction_block_component')}>
      {
        mobile ? (
          <div className="text_visuel_mobile">
            <div className="basic_info">
              <p className="name">{name}</p>
              <p className="post">{post}</p>
              <p className="dob">{dob}</p>
            </div>
            <div className={classNames('visuel')}>
              {visuel &&
                <Img
                  style={{ height: '100%', width: '100%' }}
                  fluid={{ ...visuel.fluid, aspectRatio: 0.730612245 }}
                  alt={alt}
                />
              }
              {!visuel && DefaultImage &&
                <img src={DefaultImage} alt={alt} />
              }
            </div>
            <div className="details" dangerouslySetInnerHTML={{ __html: textDetails }} >
            </div>
          </div>
        ) : (
          <div className={classNames({
            'cross_wrapper_extreme_right': extremeRight,
            'cross_wrapper_extreme_left': extremeLeft
          })}>
            <div className={classNames('text_visuel', { 'order_inverse': extremeRight })}>
              <div className={classNames('visuel')}>
                {visuel &&
                  <Img
                    style={{ height: '100%', width: '100%' }}
                    fluid={{ ...visuel.fluid, aspectRatio: 0.97166666666 }}
                    alt={alt}
                  />
                }
                {!visuel && DefaultImage &&
                  <img src={DefaultImage} alt={alt} />
                }
              </div>
              <div className={classNames('text_container')}>
                <div className={classNames('text_center')}>
                  <div className="basic_info">
                    <p className="name">{name}</p>
                    <p className="post">{post}</p>
                    <p className="dob">{dob}</p>
                  </div>
                  <div className="details" dangerouslySetInnerHTML={{ __html: textDetails }} >
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

DirectionBlock.defaultProps = {
  text: 'Default Description Description Description Description Description Description Description',
  alt: '',
};

DirectionBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool
};

export default DirectionBlock;
