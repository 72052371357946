import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import SliderComponent from '../../SliderComponent/SliderComponent';
import DirectionBlock from '../DirectionBlock/DirectionBlock';

import './ListDirectionMember.scss';
let classNames = require('classnames');

const ListDirectionMember = ({
  listDirectionMember
}) => {
  const slider_settings = {
    className: 'arrow_bottom_slider arrow_after_text',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="list_direction_member">
      <div className="only_desktop">
        {
          listDirectionMember.map((member, index) => (
            <DirectionBlock
              key={index}
              extremeRight={(index % 2 == 0) ? true : false}
              extremeLeft={(index % 2 == 0) ? false : true}
              visuel={member.visuel}
              name={member.name}
              post={member.post}
              dob={member.dob}
              textDetails={member.textDetails}
            />
          ))
        }
      </div>
      <div className="only_mobile_tablet">
        <SliderComponent settings={slider_settings}>
          {
            listDirectionMember.map((member, index) => (
              <DirectionBlock
                key={index}
                mobile={true}
                visuel={member.visuel}
                name={member.name}
                post={member.post}
                dob={member.dob}
                textDetails={member.textDetails}
              />
            ))
          }
        </SliderComponent>
      </div>
    </div>
  );
};

ListDirectionMember.defaultProps = {
  text: 'Default Description Description Description Description Description Description Description',
  alt: '',
};

ListDirectionMember.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool
};

export default ListDirectionMember;
